const programmerAltNames = "Programmer Developer";
const devOpsAltNames = "Developer Operations";
const dataAltNames = "Database Data DB analyst";
const projectManagerAltNames = "PM";
const businessAnalystAltNames = "BA";
const salesAltNames = "Sales"

const jobTitles = [
  { label: "Software Engineer Intern", altNames: programmerAltNames },
  { label: "Associate Software Engineer", altNames: programmerAltNames },
  { label: "Software Engineer", altNames: programmerAltNames },
  { label: "Senior Software Engineer", altNames: programmerAltNames },
  { label: "Lead Software Engineer", altNames: programmerAltNames },
  { label: "Software Architect", altNames: programmerAltNames },

  { label: "DevOps Engineer", altNames: devOpsAltNames },
  { label: "Lead DevOps Engineer", altNames: devOpsAltNames },

  { label: "Data Analyst", altNames: dataAltNames },
  { label: "Associate Data Engineer", altNames: dataAltNames },
  { label: "Data Engineer", altNames: dataAltNames },
  { label: "Senior Data Engineer", altNames: dataAltNames },
  { label: "Lead Data Engineer", altNames: dataAltNames },
  { label: "Data Architect", altNames: dataAltNames },

  { label: "Associate Project Manager", altNames: projectManagerAltNames },
  { label: "Project Manager", altNames: projectManagerAltNames },
  { label: "Senior Project Manager", altNames: projectManagerAltNames },

  { label: "Business Analyst", altNames: businessAnalystAltNames },

  { label: "Solutions Consultant", altNames: salesAltNames },
  { label: "Sales & Marketing Development", altNames: salesAltNames },

  { label: "CEO", altNames: "" },
  { label: "Director of Operations", altNames: "" },
  { label: "Director of Talent Engagement", altNames: "" },
  { label: "Director of Sales", altNames: "" },
  { label: "Development Manager", altNames: "" },
];

export default jobTitles;
