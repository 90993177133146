import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";

export const AppNav = () => {
  return (
    <Navbar color="light" light>
      <NavbarBrand>
        <img
          src="images/dl-logo.png"
          alt="Brand logo for Double Line"
          style={{ maxHeight: "50px" }}
        />
      </NavbarBrand>
    </Navbar>
  );
};
