import React from "react";
import PropTypes from "prop-types";
import { isNullOrWhitespace } from "./utilities";
import config from "./config"

function EmailSignature(props) {
  const hasSocialColumn = () =>
    !isNullOrWhitespace(props.facebookUrl) ||
    !isNullOrWhitespace(props.twitterUrl) ||
    !isNullOrWhitespace(props.linkedInUrl);

  const maxHeightPixels = 75;

  return (
    <div ref={props.innerRef}>
      <div>
        <table>
          <tbody>
            <tr>
              {hasSocialColumn() && (
                <td style={{ paddingRight: "20px" }}>
                  {!isNullOrWhitespace(props.facebookUrl) && (
                    <div style={{ height: "25px", marginBottom: "5px" }}>
                      <a href={props.facebookUrl}>
                        <img
                          style={{ maxHeight: "100%" }}
                          src={`${config.deployedBaseUrl}images/fb.png`}
                          alt="Facebook icon"
                        />
                      </a>
                    </div>
                  )}
                  {!isNullOrWhitespace(props.twitterUrl) && (
                    <div style={{ height: "25px", marginBottom: "5px" }}>
                      <a href={props.twitterUrl}>
                        <img
                          style={{ maxHeight: "100%" }}
                          src={`${config.deployedBaseUrl}images/tw.png`}
                          alt="Twitter icon"
                        />
                      </a>
                    </div>
                  )}
                  {!isNullOrWhitespace(props.linkedInUrl) && (
                    <div style={{ height: "25px", marginBottom: "5px" }}>
                      <a href={props.linkedInUrl}>
                        <img
                          style={{ maxHeight: "100%" }}
                          src={`${config.deployedBaseUrl}images/li.png`}
                          alt="LinkedIn icon"
                        />
                      </a>
                    </div>
                  )}
                </td>
              )}
              <td style={{ paddingRight: "20px" }}>
                <div style={{ fontWeight: "bold", color: "#1475bc" }}>
                  {props.name}
                </div>
                <div style={{ color: "#88c83e" }}>{props.jobTitle}</div>
                {!isNullOrWhitespace(props.officePhoneDisplay) && (
                  <div>
                    o:&nbsp;<a href={props.officePhoneLink}>{props.officePhoneDisplay}</a>
                  </div>
                )}
                {!isNullOrWhitespace(props.cellPhoneDisplay) && (
                  <div>
                    c:&nbsp;<a href={props.cellPhoneLink}>{props.cellPhoneDisplay}</a>
                  </div>
                )}
                {!isNullOrWhitespace(props.email) && (
                  <div>
                    e:&nbsp;<a href={`mailto:${props.email}`}>{props.email}</a>
                  </div>
                )}
              </td>
              {props.additionalLinks.length > 0 &&
                props.additionalLinks.some(
                  (l) => l.display && l.display.length > 0
                ) && (
                  <>
                    <td>
                      <div
                        style={{
                          width: "2px",
                          height: "80px",
                          margin: "6px 20px",
                          background: "#88c83e",
                        }}
                      ></div>
                    </td>
                    <td>
                      {props.additionalLinks.map((it) => (
                        <div key={it.id}>
                          <a href={it.url}>{it.display}</a>
                        </div>
                      ))}
                    </td>
                  </>
                )}
              <td>
                <div
                  style={{
                    width: "2px",
                    height: "80px",
                    margin: "6px 20px",
                    background: "#88c83e",
                  }}
                ></div>
              </td>
              <td>
                <a href="https://www.wearedoubleline.com">
                  <img
                    style={{ maxHeight: `${maxHeightPixels}px` }}
                    src={`${config.deployedBaseUrl}images/dl-logo-tagline.png`}
                    alt="Logo for Double Line. Your vision. Your students. Our mission."
                  />
                </a>
              </td>
              {/* INC 5000 or additional logo/content
              <td>
                <div
                  style={{
                    width: "2px",
                    height: "80px",
                    margin: "6px 20px",
                    background: "#88c83e",
                  }}
                ></div>
              </td>
              <td>
                <a href="https://www.inc.com/profile/double-line">
                  <img
                    style={{ maxHeight: `${maxHeightPixels*0.8}px` }}
                    src={`${config.deployedBaseUrl}images/inc-5000.png`}
                    alt="Logo for Inc. 5000 America's Fastest Growing Private Companies"
                  />
                </a>
              </td> */}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

EmailSignature.propTypes = {
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  linkedInUrl: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  email: PropTypes.string,
  officePhoneDisplay: PropTypes.string,
  officePhoneLink: PropTypes.string,
  cellPhoneDisplay: PropTypes.string,
  cellPhoneLink: PropTypes.string,
  additionalLinks: PropTypes.arrayOf(PropTypes.object),
  innerRef: PropTypes.node,
};

export default EmailSignature;
