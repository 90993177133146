import "./App.css";
import React, { useRef } from "react";
import { AppNav } from "./AppNav";
import { Instructions } from "./Instructions";
import config from "./config";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Alert,
} from "reactstrap";
import { Typeahead } from "reactstrap-typeahead";
import {
  AsYouType,
  parsePhoneNumber,
  isPossibleNumber,
} from "libphonenumber-js";
import * as clipboard from "clipboard-polyfill";
import { v4 } from "uuid";
import EmailSignature from "./EmailSignature";
import { isNullOrWhitespace, isValidUrl } from "./utilities";
import jobTitles from "./jobTitles";

function App() {
  const [name, setName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [officePhone, setOfficePhone] = React.useState("");
  const [officePhoneExt, setOfficePhoneExt] = React.useState("");
  const [cellPhone, setCellPhone] = React.useState("");
  const [cellPhoneExt, setCellPhoneExt] = React.useState("");
  const [facebookUrl, setFacebookUrl] = React.useState("");
  const [twitterUrl, setTwitterUrl] = React.useState("");
  const [linkedInUrl, setLinkedInUrl] = React.useState("");
  const [additionalLinks, setAdditionalLinks] = React.useState([]);
  const [copied, setCopied] = React.useState(false);

  const outputContainerRef = useRef();

  const cellPhoneDisplay =
    cellPhone +
    (!isNullOrWhitespace(cellPhoneExt) ? " ext. " + cellPhoneExt : "");
  const cellPhoneLink = isPossibleNumber(cellPhone, "US")
    ? parsePhoneNumber(cellPhoneDisplay, "US").format("RFC3966")
    : "";

  const officePhoneDisplay =
    officePhone +
    (!isNullOrWhitespace(officePhoneExt) ? " ext. " + officePhoneExt : "");
  const officePhoneLink = isPossibleNumber(officePhone, "US")
    ? parsePhoneNumber(officePhoneDisplay, "US").format("RFC3966")
    : "";

  const copyToClipboard = async () => {
    let clip = new clipboard.ClipboardItem({
      "text/html": new Blob([outputContainerRef.current.innerHTML], {
        type: "text/html",
      }),
    });
    await clipboard.write([clip]);
    setCopied(true);
  };

  const addLink = () => {
    setAdditionalLinks([...additionalLinks, { id: v4() }]);
  };

  const removeLink = (id) => {
    setAdditionalLinks([...additionalLinks].filter((it) => it.id !== id));
  };

  const updateLink = (id, display, url) => {
    setAdditionalLinks(
      [...additionalLinks].map((it) => {
        if (it.id === id) {
          it.display = display;
          it.url = url;
        }
        return it;
      })
    );
  };

  return (
    <div>
      <AppNav />
      <Container>
        <Instructions />
        <Card style={{ margin: "20px 0" }}>
          <CardBody>
            <Form>
              <h5>Required</h5>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) => setName(e.target.value)}
                      invalid={isNullOrWhitespace(name)}
                      valid={!isNullOrWhitespace(name)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="jobtitle">Job Title</Label>
                    <Typeahead
                      type="text"
                      name="jobtitle"
                      id="jobtitle"
                      onInputChange={(text) => setJobTitle(text)}
                      isInvalid={() => isNullOrWhitespace(jobTitle)}
                      isValid={!isNullOrWhitespace(jobTitle)}
                      options={jobTitles}
                      filterBy={["label", "altNames"]}
                      inputProps={{className: "is-invalid"}}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <h5>Optional</h5>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="officephone">Office Phone</Label>
                    <Input
                      type="officephone"
                      name="officephone"
                      id="officephone"
                      onChange={(e) =>
                        setOfficePhone(
                          new AsYouType("US").input(e.target.value, officePhone)
                        )
                      }
                      value={officePhone}
                      invalid={
                        !isNullOrWhitespace(officePhone) &&
                        !isPossibleNumber(officePhone, "US")
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="officephoneext">Ext.</Label>
                    <Input
                      type="number"
                      name="officephoneext"
                      id="officephoneext"
                      onChange={(e) => setOfficePhoneExt(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="cellphone">Cell Phone</Label>
                    <Input
                      type="cellphone"
                      name="cellphone"
                      id="cellphone"
                      onChange={(e) =>
                        setCellPhone(
                          new AsYouType("US").input(e.target.value, cellPhone)
                        )
                      }
                      value={cellPhone}
                      invalid={
                        !isNullOrWhitespace(cellPhone) &&
                        !isPossibleNumber(cellPhone, "US")
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="cellphoneext">Ext.</Label>
                    <Input
                      type="number"
                      name="cellphoneext"
                      id="cellphoneext"
                      onChange={(e) => setCellPhoneExt(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {config.isSocialMediaLinksAvailable && (
                <Row form>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="facebookurl">Facebook Profile Link</Label>
                      <Input
                        type="link"
                        name="facebookurl"
                        id="facebookurl"
                        onChange={(e) => setFacebookUrl(e.target.value)}
                        invalid={
                          !isNullOrWhitespace(facebookUrl) &&
                          !isValidUrl(facebookUrl, [
                            "facebook.com",
                            "www.facebook.com",
                          ])
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="twitterurl">Twitter Profile Link</Label>
                      <Input
                        type="link"
                        name="twitterurl"
                        id="twitterurl"
                        onChange={(e) => setTwitterUrl(e.target.value)}
                        invalid={
                          !isNullOrWhitespace(twitterUrl) &&
                          !isValidUrl(twitterUrl, [
                            "twitter.com",
                            "www.twitter.com",
                          ])
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="linkedinurl">LinkedIn Profile Link</Label>
                      <Input
                        type="link"
                        name="linkedinurl"
                        id="linkedinurl"
                        onChange={(e) => setLinkedInUrl(e.target.value)}
                        invalid={
                          !isNullOrWhitespace(linkedInUrl) &&
                          !isValidUrl(linkedInUrl, [
                            "linkedin.com",
                            "www.linkedin.com",
                          ])
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {config.isAdditionalLinksEnabled && (
                <Row style={{ margin: "20px 0" }} form>
                  <Button
                    onClick={() => addLink()}
                    color="secondary"
                    disabled={additionalLinks.length >= 4}
                  >
                    + Add additional link
                  </Button>
                </Row>
              )}
              {additionalLinks.map((item) => (
                <Row key={item.id}>
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`item-display-${item.id}`}>Display</Label>
                      <Input
                        type="text"
                        name={`item-display-${item.id}`}
                        onChange={(e) =>
                          updateLink(item.id, e.target.value, item.display)
                        }
                        invalid={isNullOrWhitespace(item.display)}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`item-url-${item.id}`}>URL</Label>
                      <Input
                        type="text"
                        name={`item-url-${item.id}`}
                        onChange={(e) =>
                          updateLink(item.id, item.display, e.target.value)
                        }
                        invalid={!isValidUrl(item.url)}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <Button
                      onClick={() => removeLink(item.id)}
                      style={{ marginTop: "2rem" }}
                      color="danger"
                    >
                      -
                    </Button>
                  </Col>
                </Row>
              ))}
            </Form>
          </CardBody>
        </Card>
        <Card style={{ margin: "20px 0" }}>
          <CardBody>
            <CardTitle tag="h3">Preview</CardTitle>
            {false && (
              <Alert color="danger">
                You have errors with the inputs above
              </Alert>
            )}
            <Container
              style={{
                marginBottom: "20px",
                overflowX: "scroll",
                padding: "10px",
                boxShadow: "5px 5px 10px",
              }}
            >
              <EmailSignature
                additionalLinks={additionalLinks}
                email={email}
                facebookUrl={facebookUrl}
                jobTitle={jobTitle}
                linkedInUrl={linkedInUrl}
                name={name}
                cellPhoneDisplay={cellPhoneDisplay}
                cellPhoneLink={cellPhoneLink}
                officePhoneDisplay={officePhoneDisplay}
                officePhoneLink={officePhoneLink}
                twitterUrl={twitterUrl}
                innerRef={outputContainerRef}
              />
            </Container>
            <Row>
              <Col md={2}>
                <Button
                  onClick={async () => await copyToClipboard()}
                  color="success"
                >
                  Copy Signature
                </Button>
              </Col>
              <Col md={10}>
                <Alert
                  color="success"
                  isOpen={copied}
                  toggle={() => setCopied(false)}
                >
                  Copied! Paste this into Gmail's signature editor directly. (Be
                  sure to clear any existing contents out!)
                </Alert>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default App;
