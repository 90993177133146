export function isNullOrWhitespace(input) {
  return !input || !input.trim();
}

export function isValidUrl(url, expectedDomain) {
  try {
    let u = new URL(url);

    if (u.protocol !== "http:" && u.protocol !== "https:") return false;
    if (expectedDomain && expectedDomain.every((d) => u.hostname !== d))
      return false;
    return true;
  } catch {
    return false;
  }
}
