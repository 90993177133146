import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Alert,
  ListGroup,
  ListGroupItem,
  Collapse,
} from "reactstrap";
import config from "./config";

export const Instructions = () => {
  const [socialHelpIsOpen, setSocialIsOpen] = React.useState(false);

  return (
    <Card style={{ margin: "20px 0" }}>
      <CardBody>
        <CardTitle tag="h3">Double Line Email Signature Generator</CardTitle>
        <Alert color="secondary">
          <strong>Note:</strong> This has been tested with the Gmail web client
          using Chrome. This only works with the browser-based Gmail client. The
          mobile apps only support a plaintext signature. Send{" "}
          <a href="mailto:m.nichols@wearedoubleline.com">
            m.nichols@wearedoubleline.com
          </a>{" "}
          a message if you run into any issues with other browsers or email
          clients.
        </Alert>
        <p>
          Fill out the form below with desired optional fields.
          {config.isSocialMediaLinksEnabled &&
            ` Social media
              profiles should be the public profile URL.`}
          {config.isAdditionalLinksAvailable &&
            ` Up to 4 additional links may be added.`}
        </p>
        <p>
          Once the signature looks correct, click{" "}
          <strong>Copy Signature</strong> below to copy the signature to the
          clipboard. Then paste directly into the signature editor using CTRL +
          V / CMD + V / Right-click {">"} Paste.
        </p>
        <p>
          For help finding the signature editor in Gmail, see{" "}
          <a target="_blank" href="https://support.google.com/mail/answer/8395?co=GENIE.Platform%3DDesktop&hl=en&oco=0">
            Create a Gmail signature
          </a>
          .
        </p>
        {config.isSocialMediaLinksEnabled && (
          <p>
            For help finding your public profile links for the social media
            platforms,{" "}
            <a
              href="javascript:;"
              onClick={() => setSocialIsOpen(!socialHelpIsOpen)}
            >
              click here
            </a>
            .
            <Collapse isOpen={socialHelpIsOpen}>
              <ListGroup>
                <ListGroupItem>
                  <strong>Facebook</strong> While logged in, click Settings &
                  Privacy {">"} Settings and copy the link next to{" "}
                  <em>Username</em>.<br />
                  Ex: https://www.facebook.com/yourusername
                </ListGroupItem>
                <ListGroupItem>
                  <strong>Twitter</strong> While logged in, click Profile, and
                  copy the link in the browser address bar.
                  <br />
                  Ex: https://www.twitter.com/yourusername
                </ListGroupItem>
                <ListGroupItem>
                  <strong>LinkedIn</strong> While logged in, click Me {">"} View
                  Profile and copy the link in the browser address bar.
                  <br />
                  Ex: https://www.linkedin.com/in/yourpublicname
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </p>
        )}
      </CardBody>
    </Card>
  );
};
